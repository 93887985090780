import * as React from 'react';
import styled from './index.module.css';
import Layout from '../../components/Layout';
import img from 'gatsby-image';
import ImageBackgroundContact from '../../images/imageComponents/ImageBackgroundContact';
import IconLetter from '../../images/imageComponents/IconLetter';
//import { useHeroImageQuery } from '../../hooks/useHeroImageQuery';

const Contact = () => {
  //const { bigHero } = useHeroImageQuery();
  return (
    <Layout>
      <div className={styled.wrapperAll}>
        <h2 className={styled.WWH2}>Woodworking Wedneday</h2>
        <div className={styled.wrapperContact}>
          <h2 className={styled.h2Contact}>Contact us</h2>
          <form name="contact" method="POST" netlify-honeypot="bot-field" data-netlify="true">
            <p className={styled.hidden}>
              <label>
                Don’t fill this out if you’re human: <input name="bot-field" />
              </label>
            </p>
            <p>
              <label>
                <input
                  className={`${styled.field} ${styled.email}`}
                  type="text"
                  name="email"
                  placeholder="Your E-Mail Goes Here"
                />
              </label>
            </p>
            <div className={styled.btnRelative}>
              <p>
                <label>
                  <textarea
                    className={`${styled.field} ${styled.textArea}`}
                    maxLength="250"
                    name="message"
                    placeholder="And Your Message Goes Here"
                  />
                </label>
              </p>
              <p>
                <button className={styled.btn} type="submit">
                  <IconLetter className={styled.icon} />
                </button>
              </p>
            </div>
          </form>
        </div>
        <img
          className={styled.backgroundImage}
          srcset="https://res.cloudinary.com/gorlipark/image/upload/w_320/v1615886223/Woodworking%20Wednesday/6_c3ochg.jpg 640w,
          https://res.cloudinary.com/gorlipark/image/upload/w_384/v1615886223/Woodworking%20Wednesday/6_c3ochg.jpg 768w,
          https://res.cloudinary.com/gorlipark/image/upload/w_512/v1615886223/Woodworking%20Wednesday/6_c3ochg.jpg 1024w,
          https://res.cloudinary.com/gorlipark/image/upload/w_800/v1615886223/Woodworking%20Wednesday/6_c3ochg.jpg 1600w,
          https://res.cloudinary.com/gorlipark/image/upload/w_960/v1615886223/Woodworking%20Wednesday/6_c3ochg.jpg 1920w"
          src="https://res.cloudinary.com/gorlipark/image/upload/w_900/v1615886223/Woodworking%20Wednesday/6_c3ochg.jpg"
          alt="some nice old tools"
        />
        <ImageBackgroundContact styling={styled.backgroundLines} />
      </div>
    </Layout>
  );
};

export default Contact;
